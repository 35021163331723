@import "/styles/themes/colors";

.tooltip {
  position: absolute;
  min-width: 200px;
  font-weight: 300;
  top: 1px;
  background-color: $red;
  border-color: $red;
  transform: translateX(-9%);
  opacity: 0.9;
  z-index: 999;
  font-size: 13px;
  color: $white;
  height: 42px;
  border-radius: 4px;
  text-align: center;
  line-height: 42px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-transition: all ease-in-out 0.25s;
  -moz-transition: all ease-in-out 0.25s;
  -o-transition: all ease-in-out 0.25s;
  -webkit-animation: alerts7 ease-in-out 1s infinite;
  -moz-animation: alerts7 ease-in-out 1s infinite;
  animation: alerts7 ease-in-out 1s infinite;

  &.red {
    background-color: $red;
    border-color: $red;
  }

  &:after {
    left: 100%;
    border-left: solid 10px $red;
    content: "";
    position: absolute;
    top: 10px;
    width: 0;
    height: 0;
    border-right: solid 10px transparent;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent;
    z-index: 99999;
    left: 100%;
  }
  &.black {
    top: 5px;
    background-color: rgba(0, 0, 0, 0.9);
    border-color: rgba(0, 0, 0, 0.9);
    &:after {
      border-left: solid 10px rgba(0, 0, 0, 0.9);
    }
  }
  &.setTopPosition {
    top: 60px;
  }
  &.setPosition {
    top: 15px;
  }
  &.right {
    &:after {
      left: 100%;
      top: 10px;
    }
  }
  &.top {
    animation: none;
    transform: none;
    left: 5%;

    &:after {
      top: -20px;
      left: 10%;
      transform: rotate(-90deg);
    }
  }
}
.animate-tooltip-large {
  -webkit-animation: tooltipLarge ease-in-out 1s infinite;
  -moz-animation: tooltipLarge ease-in-out 1s infinite;
  animation: tooltipLarge ease-in-out 1s infinite;
}

@keyframes alerts7 {
  0% {
    left: -215px;
  }
  50% {
    left: -225px;
  }
  100% {
    left: -215px;
  }
}

@keyframes tooltipLarge {
  0% {
    left: -305px;
  }
  50% {
    left: -315px;
  }
  100% {
    left: -305px;
  }
}
